.wizard-step {
	margin: 60px auto;
	max-width: 850px;
	padding: 80px 40px;
	border: 1px solid var(--border-color);
	border-radius: 6px;
	text-align: center;
}

.wizard-step .buttons-container {
	margin-top: 15px;
}

.wizard-step h3 {
	margin: 0;
	font-weight: 900;
}

@media screen and (max-width: 767px) {
	.wizard-step {
		padding: 30px 20px;
		max-width: 90%;
		margin-top: 30px;
	}
}
