.call-now-mobile-container {
	padding: 80px 20px;
}

.disclaimer-container {
	margin-top: 100px;
	margin-bottom: 100px;
	text-align: left;
	padding: 20px;
}

.disclaimer-container a {
	color: var(--text-color);
	text-decoration: none;
}

.disclaimer-container a:hover {
	text-decoration: underline;
}

.primary {
	color: var(--primary-color);
}

.wizard-step .get-quote-title {
	margin-bottom: 10px;
	font-weight: 900;
}

.wizard-step .get-quote-subtitle {
	font-size: 24px;
	font-weight: 900;
	margin-bottom: 30px;
}

.wizard-step .lets-get-you-quote-title {
	font-size: 27px;
	font-weight: 900;
	margin-bottom: 10px;
}

.wizard-step .btn.green {
	height: auto !important;
	min-height: 60px;
}
