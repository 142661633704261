.app-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	background-color: var(--primary-color);
	color: var(--header-text-color);
	padding-top: 10px;
	padding-bottom: 10px;
}

.app-header .app-logo {
	height: 80px;
	min-width: 200px;
	background: transparent var(--logo) no-repeat center;
	background-size: contain;
	cursor: pointer;
}

.app-header span {
	font-size: 20px;
}

.app-header a {
	color: var(--header-text-color);
	text-decoration: none;
}

.app-header a:hover {
	text-decoration: underline;
}

@media screen and (max-width: 767px) {
	.app-header {
		justify-content: center;
	}

	.app-header .app-logo {
		height: 50px;
	}

	.app-header :not(div.app-logo) {
		display: none;
	}
}
