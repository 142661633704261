.container-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 1140px;
	width: 100%;
	text-align: center;
	transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
}

.container-centered .border-spinner {
	margin: 0 auto;
}

@media screen and (min-width: 1200px) {
	.container-centered {
		max-width: 1140px;
	}
}

@media screen and (min-width: 992px) {
	.container-centered {
		max-width: 960px;
	}
}

@media screen and (max-width: 767px) {
	.container-centered {
		max-width: 100%;
	}
}
