@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
	font-family: 'Poppins', sans-serif;
}

.app {
	text-align: center;
}

.shadow {
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
		0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
		0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
}

.container {
	max-width: 850px;
}

.wizard-step h2 {
	font-size: 30px;
	font-weight: normal;
}

.wizard-step h3 {
	font-size: 40px;
	font-weight: 900;
}

.inputs-container {
	max-width: 40%;
	margin: 30px auto 0 auto;
}

.green {
	color: #118738;
}

.red {
	color: #ff0000;
}

.btn.green {
	background-color: #118738 !important;
}

@media screen and (max-width: 767px) {
	.input-container .form-control {
		max-width: 100%;
	}

	.inputs-container {
		max-width: 100%;
	}
}
