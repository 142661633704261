.yes-no-container {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.yes-no-container .btn-select {
	margin-left: 30px;
}

.yes-no-container .btn-select:first-child {
	margin-left: 0;
}

@media screen and (max-width: 767px) {
	.yes-no-container {
		flex-direction: column;
	}

	.yes-no-container .btn-select {
		margin-left: 0;
		margin-top: 30px;
		padding: 20px 0;
		width: 100%;
		height: auto;
	}

	.yes-no-container .btn-select:first-child {
		margin-top: 0;
	}
}
