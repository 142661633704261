.legal-disclaimer {
	text-align: left;
}

.legal-disclaimer h3 {
	margin-bottom: 30px;
}

.legal-disclaimer p {
	margin-bottom: 10px;
}

.legal-disclaimer img {
	max-width: 50%;
}
