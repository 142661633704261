.testimonial-container {
	margin: 0 auto;
	margin-bottom: 60px;
	text-align: center;
	max-width: 850px;
	width: 100%;
	padding: 60px 40px;
	border: 1px solid var(--border-color);
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: white;
}

.testimonial-container img {
	max-width: 160px;
	margin: 0 auto;
	margin-right: 30px;
	display: block;
	border-radius: 6px;
}

.testimonial-container .testimonial-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.testimonial-container span {
	display: block;
	font-size: 20px;
	font-weight: bold;
}

.testimonial-container span.location {
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 30px;
}

.testimonial-container blockquote {
	text-align: left;
	font-style: italic;
}

.testimonial-container .testimonial-person {
	text-align: right;
}

@media screen and (max-width: 767px) {
	.testimonial-container {
		margin-left: 10px;
		margin-right: 10px;
		flex-direction: column;
		justify-content: center;
	}

	.testimonial-container img {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
	}

	.testimonial-container .testimonial-content {
		justify-content: center;
		align-items: center;
	}

	.testimonial-container blockquote {
		text-align: center;
	}

	.testimonial-container .testimonial-person {
		text-align: center;
	}
}
