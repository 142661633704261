.progress-bar-container {
	background-color: var(--progress-bar-container-color);
	color: var(--progress-bar-text-color);
	padding: 20px 0;
}

.progress-bar-container h3 {
	font-size: 26px;
}

.progress-bar-container > .container {
	padding: 0;
}

.progress-bar-container .progress-bar {
	position: relative;
	border: 1px solid white;
	border-radius: 5px;
	overflow: hidden;
	background-color: transparent;
	padding: 30px 0 0 0;
	/* margin: 20px 0; */
}

.progress-bar-container .progress-bar .progress-bar-track {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	background-color: var(--progress-bar-color);
	transition: all .4s ease-out;
}

.progress-bar-container .progress-bar span {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	color: white;
}

@media screen and (max-width: 767px) {
	.progress-bar-container {
		padding: 30px 20px;
	}

	.progress-bar-container h3 {
		font-size: 16px;
	}
}
